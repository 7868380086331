table {
    tbody {
        tr {
            &.flightSelected {
            }

            &.noFlightSelected {
            }

            &.notThisFlightSelected {
                display: none;
            }

            td {
            }
        }
    }
}
